import { Box, Grid, Skeleton } from '@mui/material';

function StatsItem() {
  return (
    <Grid item xs={4}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Skeleton sx={{ width: '12px', height: '12px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <Skeleton
            sx={{
              width: '72px',
              height: '12px',
            }}
          />
          <Skeleton
            sx={{
              width: '44px',
              height: '9px',
            }}
          />
          <Skeleton
            sx={{
              width: '37px',
              height: '9px',
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
}

export function CreatorLeaderboardCardSkeleton() {
  return (
    <Grid item xs={12} md={4}>
      <Box
        sx={{
          border: '1px solid #262829',
          borderRadius: '16px',
          backgroundColor: '#111314',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          height: '374px',
        }}
      >
        <Skeleton
          sx={{
            transform: 'unset',
            height: '197px',
            width: '100%',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            py: 4,
            px: 5,
            gap: 6,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                width: '248px',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Box sx={{ display: 'flex', gap: 2, alignContent: 'center' }}>
                <Skeleton
                  sx={{
                    height: '14px',
                    width: '100px',
                  }}
                />
                <Skeleton
                  sx={{
                    width: '20px',
                    height: '12px',
                  }}
                />
              </Box>
              <Skeleton
                sx={{
                  width: { xs: '170px', md: '204px' },
                  height: '9px',
                }}
              />
              <Skeleton
                sx={{
                  width: '100px',
                  height: '9px',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                textAlign: 'right',
              }}
            >
              <Skeleton
                sx={{
                  width: '71px',
                  height: '10px',
                }}
              />

              <Skeleton
                sx={{
                  width: '55px',
                  height: '10px',
                }}
              />
            </Box>
          </Box>
          <Grid container spacing={4}>
            <StatsItem />
            <StatsItem />
            <StatsItem />
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
